import React from "react"
import Layout from '../components/shared/layout'
import LandingComponent from '../components/landing/landing.component'


const IndexPage = () => (
  <Layout header={true} footer={true}>

    <LandingComponent/>

  </Layout>
)

export default IndexPage
